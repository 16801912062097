<template>
  <div>
    <div class="d-flex justify-content-between">
      <h3>История оплаты</h3>
      <b-button
        variant="primary"
        @click="openModal = true"
      >
        <span class="mr-25 mb-2 align-middle">
          <feather-icon icon="PlusIcon" />
          {{ $t('Добавить оплата') }}</span>
      </b-button>
    </div>
    <!-- payments -->
    <div class="">
      <div class="card">
        <good-table-contracts
          v-if="payments.data && payments.data.length"
          :items="payments.data"
          :columns="columns"
          :total="payments.total"
          :page="filterModel.page"
          :has-show="true"
          model="payment"
          :filter="filterModel"
          @getItems="getPayments"
          @onPageChange="(p) => filterModel.page = p"
        />
        <div v-else  class="p-2">
          Нет оплаты
        </div>

      </div>
    </div>
    <!-- <template v-if="provider.hired_worker_user_id">
      <CreateSumPayment
        v-if="openModal"
        :show="openModal"
        @onSuccess="savePayment"
        @close="openModal=false"
      />
    </template>
    <template v-else> -->
    <CreatePayment
      v-if="openModal"
      :show="openModal"
      :provider="provider"
      @onSuccess="savePayment"
      @close="openModal=false"
    />
    <!-- </template> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GoodTableContracts from '@/views/table/vue-good-table/GoodTableContracts.vue'
import CreatePayment from './form.vue'
import CreateSumPayment from './sum-form.vue'

export default {
  name: 'Create',
  components: {
    CreatePayment, CreateSumPayment, GoodTableContracts,
  },
  props: {
    provider: {
      type: Object,
      default: () => {},
    },

  },
  data() {
    return {
      openModal: false,
      loading: false,
      filterModel: {
        page: 1,
        per_page: 10,
      },
      columns: [
        {
          label: 'Дата',
          field: 'created_at',
          isDate: true,
        },
        {
          field: 'amount',
          label: 'Сумма',
          isAmount: true,
        },
        {
          field: 'comment',
          label: 'Комментарий',
        },
        {
          field: 'created_user.name',
          label: 'Кто зарегистрирован',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({ payments: 'paymentForProviders/GET_ITEMS' }),
    itemId() {
      return this.$route.params.id
    },
  },
  watch: {
    'filterModel.page': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getPayments()
    },
    'filterModel.per_page': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getPayments()
    },
  },
  mounted() {
    this.title = this.$t('Добавить поставщик')
    this.getPayments()
    if (!this.provider.hired_worker_user_id) {
      this.columns.push({
        field: 'amount_dollar',
        isAmount: true,
        label: 'Сумма(USD)',
      })
    }
  },
  methods: {
    getPayments() {
      this.getAction({ ...this.filterModel, provider_id: this.itemId, relations: 'createdUser' })
    },
    savePayment() {
      this.getPayments()
      this.getPartiesAction({ relations: 'provider', provider_id: this.itemId })
      this.openModal = false
    },
    ...mapActions({
      getAction: 'paymentForProviders/index',
      getPartiesAction: 'parties/index',
    }),
  },
}
</script>

<style scoped>

</style>

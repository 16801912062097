<template>
  <div class="">
    <div class="row">
      <div class="col-12 col-md-5">
        <h3>Информации поставщика</h3>
        <div class="card">
          <table class="p-2 table table-bordered table-responsive">
            <tbody v-if="provider">
              <tr>
                <th>Именование</th>
                <td>{{ provider.name }}</td>
              </tr>
              <tr>
                <th>Адрес</th>
                <td>{{ provider.address }}</td>
              </tr>
              <tr>
                <th>Telefon</th>
                <td>{{ provider.phone }}</td>
              </tr>
              <template v-if="!provider.hired_worker_user_id">
                <tr>
                  <th>ИНН</th>
                  <td>{{ provider.tin }}</td>
                </tr>
                <tr>
                  <th>Рассчетный счет</th>
                  <td>{{ provider.account_number }}</td>
                </tr>
                <tr>
                  <th>Банк</th>
                  <td>{{ provider.bank_name }}</td>
                </tr>
                <tr>
                  <th>МФО Банка</th>
                  <td>{{ provider.mfo }}</td>
                </tr>
                <tr>
                  <th>Директор ФИО</th>
                  <td>{{ provider.director_fio }}</td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <th colspan="2" class="text-primary text-center">
                    Ёлланма ишчи
                  </th>
                </tr>
              </template>

              <tr>
                <th>Когда зарегистрирован</th>
                <td>{{ formatDateYmd(provider.created_at) }}</td>
              </tr>
            </tbody>
          </table>
          <!-- Payed Info -->
          <div
            v-if="provider && provider.all_payment_amount_dollar"
            class="ml-1 mt-2"
          >
            <p>
              <b>Оплачено:</b>
              <span class="text-success">
                {{ provider.all_payment_amount_dollar | formatPrice }} USD</span
              >
            </p>
            <p>
              <b>Не оплачено:</b>
              <span class="text-warning">
                {{
                  (provider.all_amount_dollar -
                    provider.all_payment_amount_dollar)
                    | formatPrice
                }}
                USD</span
              >
            </p>
            <p>
              <b>Итого:</b>
              <span class="text-primary">
                {{ provider.all_amount_dollar | formatPrice }} USD</span
              >
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-7">
        <PaymentForProvider :provider="provider" />
      </div>
    </div>
    <!-- parties -->
    <!-- <ProviderPaymentTable :parties="parties" /> -->
    <div class="">
      <div class="card">
        <div class="d-flex justify-content-between">
          <h3 class="pl-2 pt-2">Партии</h3>
          <b-button
            variant="outline-success"
            class="float-right mt-2 mr-2"
            size="small"
            :disabled="xLoading"
            @click="tableToExcel"
          >
            <img src="/images/excel_1.svg" alt="" />
            Yuklab olish
          </b-button>
        </div>
        <good-table-contracts
          v-if="parties.data && parties.data.length"
          :items="parties.data"
          :columns="columns"
          :total="parties.total"
          :page="filterModel.page"
          :has-show="true"
          model="warehouseParty"
          :filter="filterModel"
          @getItems="getParties"
          @onPageChange="(p) => (filterModel.page = p)"
        />
        <div v-else>Нет оплаты</div>
        <good-table-contracts
          v-show="false"
          ref="tablee"
          :items="xParties"
          :columns="columns"
          class="tablee"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GoodTableContracts from "@/views/table/vue-good-table/GoodTableContracts.vue";
import PaymentForProvider from "./payment/show.vue";

export default {
  name: "Create",
  components: {
    PaymentForProvider,
    GoodTableContracts,
  },
  props: {},
  data() {
    return {
      xLoading: false,
      filterModel: {
        page: 1,
        per_page: 10,
      },
      xParties: [],
      //
      uri: "data:application/vnd.ms-excel;base64,",
      template:
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta charset="utf-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64(s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      },
      format(s, c) {
        return s.replace(/{(\w+)}/g, (m, p) => c[p]);
      },
    };
  },
  computed: {
    ...mapGetters({
      provider: "providers/GET_ITEM",
      parties: "parties/GET_ITEMS",
    }),
    itemId() {
      return this.$route.params.id;
    },
    columns() {
      return [
        {
          label: this.$t("Поставщик"),
          field: "provider.name",
          showCashField: true,
        },
        {
          label: this.$t("Дата"),
          field: "created_at",
          isDate: true,
        },
        {
          label: this.$t("Комментарий"),
          field: "comment",
        },
        {
          label: this.$t("Оплачено"),
          field: "payment_amount",
          isAmount: true,
        },
        {
          label: this.$t("Сумма(UZS)"),
          field: "cost",
          isAmount: true,
        },
        {
          label: this.$t("Сумма(USD)"),
          field: "cost_dollar",
          isAmount: true,
        },
        {
          label: this.$t("Кто зарегистрирован"),
          field: "created_user.name",
        },
        {
          label: this.$t("Статус"),
          field: "pstatus",
          isPaymentStatus: true,
        },
      ];
    },
  },
  watch: {
    "filterModel.page": function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getParties();
    },
    "filterModel.per_page": function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getParties();
    },
  },
  mounted() {
    this.title = this.$t("Добавить поставщик");
    this.getProvider(this.itemId);
    this.getParties();
  },
  methods: {
    getProvider(id) {
      this.getItemShow({
        id,
        appends: "all_amount_dollar|all_payment_amount_dollar",
      });
    },
    async getParties() {
      this.loading = true;
      await this.getPartiesAction({
        ...this.filterModel,
        relations: "provider|createdUser",
        provider_id: this.itemId,
      });
      this.loading = false;
    },
    async tableToExcel(table) {
      this.xLoading = true;
      await this.getPartiesAction({
        page: 1,
        per_page: 9999,
        relations: "provider|createdUser",
        provider_id: this.itemId,
      }).then((res) => {
        this.xParties = res.data.data;
        const tableeee = document.querySelector(
          "div.tablee div.vgt-responsive table"
        );
        setTimeout(() => {
          if (!table.nodeType) table = tableeee;
          const ctx = { worksheet: `${this.title}`, table: table.innerHTML };
          const link = document.createElement("a");
          link.download = `${this.title}.xls`;
          link.href = this.uri + this.base64(this.format(this.template, ctx));
          link.click();
          this.xLoading = false;
        }, 10);
      });
    },
    ...mapActions({
      getItemShow: "providers/show",
      getPartiesAction: "parties/index",
    }),
  },
};
</script>

<style scoped></style>

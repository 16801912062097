var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"no-close-on-backdrop":"","centered":"","hide-footer":"","title":_vm.title},on:{"close":_vm.close},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2"},[_c('div',{staticClass:"d-flex"},[_c('b-form-group',{staticClass:"w-50",attrs:{"label":_vm.$t('Курс валют'),"label-for":"dollar_rate"}},[_c('validation-provider',{attrs:{"name":_vm.$t('валюта'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"dollar_rate","state":errors.length > 0 ? false:null,"options":_vm.cleaveOption,"placeholder":"0"},on:{"input":function($event){return _vm.onchangeRate(_vm.form.dollar_rate)}},model:{value:(_vm.form.dollar_rate),callback:function ($$v) {_vm.$set(_vm.form, "dollar_rate", $$v)},expression:"form.dollar_rate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"ml-2",attrs:{"label":_vm.$t('Способ оплаты'),"label-for":"payment_type_id"}},[_c('validation-provider',{attrs:{"name":"payment_type_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"payment_type_id","value-field":"id","text-field":"name_ru","placeholder":_vm.$t('Способ оплаты'),"options":_vm.payment_types},model:{value:(_vm.form.payment_type_id),callback:function ($$v) {_vm.$set(_vm.form, "payment_type_id", $$v)},expression:"form.payment_type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex"},[(_vm.isUsd)?_c('b-form-group',{attrs:{"label":_vm.$t('Сумма в USD'),"label-for":"amount_dollar"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Сумма'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"amount_dollar","state":errors.length > 0 ? false:null,"options":_vm.cleaveOption,"placeholder":"0"},on:{"input":function($event){return _vm.onchangeAmountDollar(_vm.form.amount_dollar)}},model:{value:(_vm.form.amount_dollar),callback:function ($$v) {_vm.$set(_vm.form, "amount_dollar", $$v)},expression:"form.amount_dollar"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_c('b-form-group',{attrs:{"label":_vm.$t('Сумма в UZS'),"label-for":"amount"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Сумма'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"amount","state":errors.length > 0 ? false:null,"options":_vm.cleaveOption,"placeholder":"0"},on:{"input":function($event){return _vm.onchangeAmount(_vm.form.amount)}},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"mt-2 ml-1"},[_c('b-button',{staticClass:"btn-tour-skip",attrs:{"variant":"info","size":"sm"},on:{"click":_vm.changeCurrency}},[_c('feather-icon',{attrs:{"icon":"RepeatIcon","size":"14"}})],1)],1),(!_vm.isUsd)?_c('b-form-group',{staticClass:"ml-3",attrs:{"label":_vm.$t('Сумма в USD'),"label-for":"amount_dollar"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Сумма'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"amount_dollar","disabled":"","state":errors.length > 0 ? false:null,"options":_vm.cleaveOption,"placeholder":"0"},model:{value:(_vm.form.amount_dollar),callback:function ($$v) {_vm.$set(_vm.form, "amount_dollar", $$v)},expression:"form.amount_dollar"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_c('b-form-group',{staticClass:"ml-3",attrs:{"label":_vm.$t('Сумма в UZS'),"label-for":"amount"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Сумма'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"amount","disabled":"","state":errors.length > 0 ? false:null,"options":_vm.cleaveOption,"placeholder":"0"},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-form-group',{attrs:{"label":_vm.$t('Комментарий'),"label-for":"comment"}},[_c('validation-provider',{attrs:{"name":"comment","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{staticClass:"form-control",attrs:{"id":"comment","state":errors.length > 0 ? false:null,"placeholder":"Комментарий","rows":"3"},model:{value:(_vm.form.comment),callback:function ($$v) {_vm.$set(_vm.form, "comment", $$v)},expression:"form.comment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"text-right mt-3"},[_c('b-button',{staticClass:"btn-tour-skip",attrs:{"variant":"primary"},on:{"click":_vm.save}},[_c('span',{staticClass:"mr-25 align-middle"},[_vm._v("Сохранить")])])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
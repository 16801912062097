<template>
  <b-modal
    v-model="visible"
    no-close-on-backdrop
    centered
    hide-footer
    :title="title"
    @close="close"
  >
    <validation-observer
      ref="form"
      #default="{invalid}"
    >
      <b-form
        class="auth-login-form mt-2"
      >
        <div class="d-flex">
          <!-- input  -->
          <b-form-group
            :label="$t('Сумма в UZS')"
            label-for="amount"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Сумма')"
              rules="required"
            >
              <cleave
                id="amount"
                v-model="form.amount"
                class="form-control"
                :state="errors.length > 0 ? false:null"
                :options="cleaveOption"
                placeholder="0"
                @input="onchangeAmount(form.amount)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>

        <b-form-group
          :label="$t('Комментарий')"
          label-for="comment"
        >
          <validation-provider
            #default="{ errors }"
            name="comment"
            rules="required"
          >
            <b-form-textarea
              id="comment"
              v-model="form.comment"
              class="form-control"
              :state="errors.length > 0 ? false:null"
              placeholder="Комментарий"
              rows="3"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <div
          class="text-right mt-3"
        >
          <b-button
            variant="primary"
            class="btn-tour-skip"
            @click="save"
          >
            <span class="mr-25 align-middle">Сохранить</span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import { clearObject } from '@/utils'
import Cleave from 'vue-cleave-component'

export default {
  name: 'Create',
  components: {
    ValidationProvider, ValidationObserver, Cleave,
  },
  props: ['show'],
  data() {
    return {
      form: {
        id: null,
        provider_id: null,
        amount: '',
        amount_dollar: null,
        dollar_rate: null,
        comment: '',
      },
      isUsd: true,
      isUzs: false,
      cleaveOption: { numeral: true, numeralThousandsGroupStyle: 'thousand' },
      title: '',
      visible: false,
      required,
    }
  },
  computed: {
    itemId() {
      return this.$route.query.id
    },
  },
  watch: {
    visible(newVal) {
      if (!newVal) setTimeout(() => { clearObject(this.form) }, 200)
    },
  },
  mounted() {
    this.title = this.$t('Добавить платеж')

    if (this.show) {
      this.visible = true
    }
  },
  methods: {
    close() {
      this.$emit('close', true)
      this.visible = false
    },
    changeCurrency() {
      if (this.isUsd) {
        this.isUzs = true
        this.isUsd = false
      } else {
        this.isUzs = false
        this.isUsd = true
      }
      this.form.amount = null
    },
    async save() {
      const valid = await this.validationForm()
      if (valid) {
        this.form.provider_id = this.$route.params.id
        this.method(this.form).then(res => {
          showToast('success', this.$t('Успешно сохранено'), 'CheckCircleIcon')
          this.$emit('onSuccess')
          this.visible = false
        }).catch(err => {
          if (err.status === 422) {
            this.$refs.form.setErrors(err.data.errors)
          }
          showToast('danger', this.$t('Ошибка'), 'XIcon')
        })
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    edit(item) {
      this.form.id = item.id
      this.form.name_uz = item.name_uz
      this.form.name_ru = item.name_ru
      this.visible = true
    },
    method(data) {
      return this.storeItem(data)
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    onchangeAmountDollar(val) {
      if (val && this.form.dollar_rate) {
        this.form.amount = Number(this.form.dollar_rate) * Number(val)
      }
    },
    onchangeAmount(val) {
      if (val && this.form.dollar_rate && this.isUzs) {
        this.form.amount_dollar = (Number(val) / Number(this.form.dollar_rate)).toFixed(1)
      }
    },
    onchangeRate(val) {
      if (val && this.form.amount_dollar && this.isUsd) {
        this.form.amount = Number(this.form.amount_dollar) * Number(val)
      } else if (val && this.form.amount) {
        this.form.amount_dollar = (Number(this.form.amount) / Number(val)).toFixed(1)
      }
    },
    ...mapActions({
      storeItem: 'paymentForProviders/paymentForHiredWorkers',
    }),
  },
}
</script>

<style scoped>

</style>
